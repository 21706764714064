import { NoCodeCellDataframe, getChartReferencedDataFrames } from "@hex/common";

import { CellContentsMP } from "../../../redux/slices/hexVersionMPSlice.js";

/**
 * This returns the raw dataframe (either string or a DataSourceTableConfig object)
 * used in a specific cell.
 */
export function getDataFramesForCell(
  cellContents: CellContentsMP,
): readonly NoCodeCellDataframe[] {
  if (cellContents.__typename === "ChartCell") {
    return getChartReferencedDataFrames(cellContents.chartSpec);
  } else if (cellContents.__typename === "DisplayTableCell") {
    if (cellContents.dataframe != null) {
      return [cellContents.dataframe];
    } else {
      return [];
    }
  } else if (cellContents.__typename === "PivotCell") {
    if (cellContents.dataframe != null) {
      return [cellContents.dataframe];
    } else {
      return [];
    }
  } else if (cellContents.__typename === "FilterCell") {
    if (cellContents.dataframe != null) {
      return [cellContents.dataframe];
    } else {
      return [];
    }
  } else if (cellContents.__typename === "MapCell") {
    return cellContents.map.layers.flatMap((layer) =>
      layer.type !== "dataset" && layer.data.dataFrameName != null
        ? [layer.data.dataFrameName]
        : [],
    );
  }
  return [];
}
