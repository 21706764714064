import { gql } from "@apollo/client";
import { Classes, Intent, TooltipProps } from "@blueprintjs/core";
import {
  CREATE_DATA_CONNECTION_HEX_VERSION_LINK,
  DataConnectionHexVersionLinkId,
  DataConnectionId,
  DataSourceTableConfig,
  DataSourceTableId,
  getDataframeDisplayName,
  uuid,
} from "@hex/common";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { HexButton } from "../../../../hex-components/HexButton.js";
import { HexSpinner } from "../../../../hex-components/HexSpinner.js";
import { HexTooltip } from "../../../../hex-components/HexTooltip.js";
import {
  importedDataConnectionIdsSetSelector,
  useDataConnectionHexVersionLinksSelector,
} from "../../../../hex-version-multiplayer/state-hooks/dataConnectionHexVersionLinkStateHooks.js";
import { useProjectVersionEditable } from "../../../../hooks/sessionOrProjectEditableHooks.js";
import { useTerminology } from "../../../../hooks/useTerminology.js";
import { useHexVersionAOContext } from "../../../../util/hexVersionAOContext.js";
import { useDataSourceTableByIdQuery } from "../../../data/schema-browser-v2/queries.generated.js";
import { TableCellIcon, WarningIcon } from "../../../icons/CustomIcons.js";
import { useTableNodeIdPathGetter } from "../../renderers/sql/sqlCellHooks.js";

import {
  DataPillTooltipContent,
  DataPillTooltipContentWrapper,
} from "./DataPillTooltipContent.js";
import { useGetDataConnectionInfoForDataSourceTooltipQuery } from "./DataSourceTablePill.generated.js";

gql`
  query GetDataConnectionInfoForDataSourceTooltip(
    $dataConnectionId: DataConnectionId!
  ) {
    dataConnectionById(dataConnectionId: $dataConnectionId) {
      id
      connectionType
    }
  }
`;

const DataSourceTableWrapper = styled.div`
  ${({ theme }) => theme.pill["GRAY"].css};
  width: fit-content;
  gap: 4px;
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  align-items: center;
`;

const StyledWarningIcon = styled(WarningIcon)<{ intent: Intent }>`
  svg {
    color: ${({ intent, theme }) => theme.button.default[intent].fontColor};
  }
`;

const DataSourceTableIcon = styled(TableCellIcon)`
  &&&& {
    margin: 0;
  }
`;

const TableName = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.MONO};
  word-wrap: break-word;
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
`;

interface DataSourceTablePillWithTooltipProps {
  dataSourceTableConfig: DataSourceTableConfig;
  onOpenDataBrowser: ({
    dataConnectionId,
    nodeIdPath,
    tableId,
  }: {
    dataConnectionId: DataConnectionId | null;
    tableId: DataSourceTableId | null;
    nodeIdPath: string[] | null;
  }) => void;
  stopClickPropagation?: boolean;
  tooltipProps?: Pick<
    TooltipProps,
    "disabled" | "hoverOpenDelay" | "position" | "interactionKind"
  >;
}

export const DataSourceTablePillWithTooltip: React.ComponentType<DataSourceTablePillWithTooltipProps> =
  React.memo(function DataSourceTablePillWithTooltip({
    dataSourceTableConfig,
    onOpenDataBrowser,
    stopClickPropagation,
    tooltipProps,
  }) {
    const tableName = getDataframeDisplayName(dataSourceTableConfig);
    const tableId = dataSourceTableConfig.dataSourceTableId;
    const dataConnectionId = dataSourceTableConfig.connectionId;

    const { dataBrowserActionText } = useTerminology();
    const projectVersionEditable = useProjectVersionEditable();

    const {
      data: tableData,
      error: tableError,
      loading: tableLoading,
    } = useDataSourceTableByIdQuery({
      variables: { dataSourceTableId: tableId },
    });

    const {
      data: dataConnectionData,
      error: dataConnectionError,
      loading: dataConnectionLoading,
    } = useGetDataConnectionInfoForDataSourceTooltipQuery({
      variables: { dataConnectionId: dataConnectionId },
    });
    const loading = tableLoading || dataConnectionLoading;

    const getTableIdPath = useTableNodeIdPathGetter();

    const importedConnections = useDataConnectionHexVersionLinksSelector({
      selector: importedDataConnectionIdsSetSelector,
    });

    const openDataBrowser = useCallback(
      async (evt) => {
        if (stopClickPropagation) {
          evt.stopPropagation();
        }
        if (tableData != null && dataConnectionData != null) {
          const nodeIdPath = getTableIdPath({
            connectionType:
              dataConnectionData.dataConnectionById.connectionType,
            tableId,
            schemaId: tableData.dataSourceTableById.dataSourceSchema.id,
            databaseId:
              tableData.dataSourceTableById.dataSourceSchema.dataSourceDatabase
                .id,
          });

          onOpenDataBrowser({
            dataConnectionId: dataConnectionData ? dataConnectionId : null,
            nodeIdPath,
            tableId: tableId,
          });
        } else {
          // if data connection exists, just open to the data connection,
          // otherwise just open to default state
          onOpenDataBrowser({
            dataConnectionId: dataConnectionData ? dataConnectionId : null,
            nodeIdPath: null,
            tableId: null,
          });
        }
      },
      [
        stopClickPropagation,
        tableData,
        dataConnectionData,
        getTableIdPath,
        tableId,
        onOpenDataBrowser,
        dataConnectionId,
      ],
    );
    const { dispatchAO } = useHexVersionAOContext();
    const importDataConnection = useCallback(() => {
      dispatchAO(
        CREATE_DATA_CONNECTION_HEX_VERSION_LINK.create({
          dataConnectionHexVersionLinkId:
            uuid() as DataConnectionHexVersionLinkId,
          dataConnectionId,
        }),
      );
    }, [dataConnectionId, dispatchAO]);

    const { tooltipContent, warnIntent } = useMemo(() => {
      if (loading) {
        return {
          tooltipContent: <HexSpinner />,
          warnIntent: undefined,
        };
      }
      if (dataConnectionError) {
        return {
          tooltipContent: (
            <>
              The data connection for this table does not exist. It may have
              been deleted or you do not have access to it.
              {projectVersionEditable && (
                <HexButton
                  className={Classes.POPOVER_DISMISS}
                  intent="primary"
                  onClick={openDataBrowser}
                >
                  {dataBrowserActionText}
                </HexButton>
              )}
            </>
          ),
          warnIntent: Intent.DANGER,
        };
      }
      if (tableError) {
        return {
          tooltipContent: (
            <>
              <div>
                The table at{" "}
                <TableName>{dataSourceTableConfig.pathToTable}</TableName> no
                longer exists in the data connection schema. Either refresh your
                schema if this table should exist, or try selecting another
                table.
              </div>
              {projectVersionEditable && (
                <HexButton
                  className={Classes.POPOVER_DISMISS}
                  intent="primary"
                  onClick={openDataBrowser}
                >
                  {dataBrowserActionText}
                </HexButton>
              )}
            </>
          ),
          warnIntent: Intent.WARNING,
        };
      }
      if (tableData && dataConnectionData) {
        if (!importedConnections.has(dataConnectionId)) {
          return {
            tooltipContent: (
              <>
                This data connection has not been imported into this project.
                {projectVersionEditable && (
                  <HexButton
                    className={Classes.POPOVER_DISMISS}
                    intent="primary"
                    onClick={importDataConnection}
                  >
                    Import connection
                  </HexButton>
                )}
              </>
            ),
            warnIntent: Intent.DANGER,
          };
        }
        const description =
          tableData.dataSourceTableById.metadata?.magicDescription ||
          tableData.dataSourceTableById.dbtDescriptionMdStripped ||
          tableData.dataSourceTableById.comment;
        return {
          tooltipContent: (
            <DataPillTooltipContent
              actionName="View table detail"
              columns={tableData.dataSourceTableById.columns}
              description={description}
              tableName={tableName}
              onAction={projectVersionEditable ? openDataBrowser : undefined}
            />
          ),
          warnIntent: undefined,
        };
      }
      // we should never get here
      return {
        tooltipContent: <></>,
        warnIntent: undefined,
      };
    }, [
      loading,
      dataConnectionError,
      tableError,
      tableData,
      dataConnectionData,
      projectVersionEditable,
      openDataBrowser,
      dataBrowserActionText,
      dataSourceTableConfig.pathToTable,
      importedConnections,
      dataConnectionId,
      tableName,
      importDataConnection,
    ]);

    return (
      <HexTooltip
        content={
          <DataPillTooltipContentWrapper>
            {tooltipContent}
          </DataPillTooltipContentWrapper>
        }
        hoverCloseDelay={200}
        interactionKind="hover"
        position="right"
        {...tooltipProps}
        // eslint-disable-next-line react/jsx-no-bind -- won't hurt in this component
        renderTarget={(props) => (
          // wrapping everything in a flex div makes sure the height stays right surprisingly
          <div {...props} css="display: flex">
            <DataSourceTablePill name={tableName} warningIntent={warnIntent} />
          </div>
        )}
      />
    );
  });

interface DataSourceTablePillProps {
  name: string;
  className?: string;
  warningIntent?: Intent;
}

export const DataSourceTablePill: React.ComponentType<DataSourceTablePillProps> =
  React.memo(function DataSourceTablePill({ className, name, warningIntent }) {
    return (
      <DataSourceTableWrapper className={className}>
        <DataSourceTableIcon />
        {name} {warningIntent && <StyledWarningIcon intent={warningIntent} />}
      </DataSourceTableWrapper>
    );
  });
